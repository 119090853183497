<template>
	<div class="group-item" :class="active ? 'active' : ''">
		<div class="group-avatar">
			<head-image :size="48" :name="group.name" :url="group.headImage"> </head-image>
		</div>
		<div class="group-name">
			<div>{{group.remark||group.name}}</div>
		</div>
	</div>
</template>

<script>
	import HeadImage from '../common/HeadImage.vue';

	export default {
		name: "groupItem",
		components: {
			HeadImage
		},
		data() {
			return {}
		},
		props: {
			group: {
				type: Object
			},
			active: {
				type: Boolean
			}
		}

	}
</script>

<style lang="scss" >
	.group-item {
		padding:14px 8px;
		box-sizing: border-box;
		display: flex;
		position: relative;
		align-items: center;
		white-space: nowrap;
		cursor: pointer;
		border-radius: 8px;
		&:hover {
			background: rgba(246, 246, 252, 0.8);
		}
	
		&.active {
			background: #EEEEF8;
		}

		.group-name {
			font-size: 16px;
			color: #333333;
			padding-left: 10px;
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
		}
	}
</style>
