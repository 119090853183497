<template>
	<el-container class="group-channel">
		<el-aside width="300px" class="group-list-box">
			<div class="group-list-header">
				<div class="group-list-search">
					<el-input prefix-icon="el-icon-search" :placeholder="$t('comm.search')" v-model="searchText"></el-input>
				</div>
			</div>
			<div>
				<slot></slot>
			</div>
			<el-scrollbar class="group-list-items">
				<div v-for="(group,index) in groupStore.groups" :key="group.id">
					<template v-if="group.groupType == '2'">
						<group-item v-show="!group.quit&&(group.remark||group.name).startsWith(searchText)" :group="group"
							:active="group === groupStore.activechannel" @click.native="onActiveItem(group,index)">
						</group-item>
					</template>
				</div>
			</el-scrollbar>
		</el-aside>
		<el-container class="chat-box">
			<chat-box v-if="chatStore.activeChat" :chat="chatStore.activeChat"  chatType="GROUP"></chat-box>	
		</el-container>
	</el-container>
</template>
                                           

<script>
	import ChatBox from "../components/chat/ChatBox.vue";
	import GroupItem from '../components/group/GroupItem';
	import ChatItem from "../components/chat/ChatItem.vue";
	export default {
		name: "group",
		components: {
			ChatBox,
			GroupItem,
			ChatItem
		},
		data() {
			return {
				searchText: "",
			};
		},
		mounted() {
			this.$store.commit("activeChat",-1);
			//切换页签打开上次的窗口
			const activeChannel = this.groupStore.activechannel
			if(activeChannel){
				let index = this.chatStore.chats.findIndex((gchat)=>{
					return gchat.targetId == activeChannel.id&&gchat.type=='GROUP'
				})
				if(index>=0){
					this.onActiveCharItem(index)
				}
			}
		},
		methods: { 
			onActiveItem(group, index) {
				const activeChannel = JSON.parse(JSON.stringify(group));
				let chat = {
					type: 'GROUP',
					targetId: activeChannel.id,
					showName: activeChannel.remark||activeChannel.name,
					headImage:activeChannel.headImage,
					groupType: activeChannel.groupType,
					disturbing: activeChannel.disturbing,
				};
				this.$store.commit("openChat", chat);
				this.onActiveCharItem(0)
			},
			onActiveCharItem(index) {
				this.$store.commit("activeChat", index);
				let selid = this.chatStore.activeChat.targetId 
				let groupindex  = this.groupStore.groups.findIndex((gchat)=>{
					return gchat.id === selid
				})
				this.$store.commit("activechGroup", groupindex);
			},
			onDelItem(index) {
				this.$store.commit("removeChat", index);
			},
			onTop(index) {
				this.$store.commit("moveTop", index);
			}
		},
		computed: {
			groupStore() {
				return this.$store.state.groupStore;
			},
			chatStore() {
				return this.$store.state.chatStore;
			},
			loading(){
				return this.chatStore.loadingGroupMsg || this.chatStore.loadingPrivateMsg
			}
		}
	}
</script>

<style scoped lang="scss">
	.group-channel {
		.group-list-box {
			display: flex;
			flex-direction: column;
			background: white;
			padding-right: 40px;
			padding-left: 16px;
			.group-list-header{
				padding: 20px 0px 16px 0px;
				display: flex;
				.group-list-search{
					flex: 1;
					::v-deep{
						.el-input__icon{
							line-height: 40px;
						}
						input{
							font-size: 16px;
							color: #626569;
							background-color: #DBDCFF;
							border-radius: 12px;
							border: none;
							height: 40px;
							&::placeholder{
								color: #626569;
							}
						}
						.el-input__icon{
							color: #626569;
						}
					} 
				}
			}
			.group-list-items {
				flex: 1;
			}
			::v-deep{
				.el-scrollbar__wrap{
					margin:0 !important;
				}
				.is-vertical{
					display: none;
				}
			}
		}

	}
</style>